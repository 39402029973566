

const UpcomingShows = () => {
    return (
        <div className="upcoming-shows">
            <div className="container">
                <div className="show-wrapper">
{/* 
                <div class="show">
                    <h2>Lonely god presents:</h2>
                    <p>Street Nights (solo) & Roseblood (solo)</p>
                    <p>Sunday June 30th at Dreamhouse upstairs</p>
                    <p>FREE (tips encouraged)</p>
                </div> */}
{/* 
                <div class="show">
                    <h2>Les Savy Fav w/Blesst Chest</h2>
                    <p>Revolution Hall Friday July 12</p>
                    <a target="_blank" href="https://www.etix.com/ticket/p/33937445/les-savy-fav-portland-revolution-hall?_gl=1*g4nac0*_gcl_au*NzE5ODE1NTMwLjE3MTg1MDY4OTU.*_ga*MTMzMTA5NTU4My4xNzE4NTA2ODk1*_ga_PQG6FGEXJD*MTcxODUwNjg5NS4xLjEuMTcxODUwNjkxNi4wLjAuMA..&_ga=2.36759973.406015303.1718506896-1331095583.1718506895">Get Tickets</a>

                </div> */}
                <div className="show">
                <h2>Street Nights</h2>
                <p>Festival of Dark Arts</p>
                <p style={{ marginBottom: "30px" }}>2/15/25</p>
                <a

                    className="button"
                    target="_blank"
                    rel="noreferrer"
                    href="https://fortgeorgebrewery.com/festival-of-dark-arts/"
                >
                    FESTIVAL OF DARK ARTS
                </a>
                </div>



            

                {/* <div class="show">
                    <h2>Street Nights </h2>
                    <p>High Water Fest Nehalem, OR</p>
                    <p>9/13</p>
                </div>

                <div class="show">
                    <h2>Street Nights </h2>
                    <p>Record Release W/Ache Mono and Swinging Mississippi Studios</p>
                    <p>10/27</p>
                    <a className="button"
                     target="_blank" rel="noreferrer" href="https://www.etix.com/ticket/p/49524349/street-nights-album-release-portland-mississippi-studios?fbclid=PAZXh0bgNhZW0CMTEAAaZC3yi1KhESm0afEOm_kbQ2qwJbZrtdWRhm7EH4N1830Z1rdSPzgj0lozI_aem_l4fVV16z9mUh2BQrma0B0g">GET TICKETS</a>
                </div>

                <div class="show">
                    <h2>Blesst Chest</h2>
                    <p>Fixin To</p>
                    <p>11/22</p>
                </div> */}


                </div>

            </div>

        </div>
    )
}

export default UpcomingShows;